import React, { useEffect } from 'react';
import bg from '../../assets/andrew_bg.jpg';
import { FiCheck } from 'react-icons/fi';
import { FaXTwitter } from "react-icons/fa6";
import book from '../../assets/book_img.png';
import { ValidationError, useForm } from '@formspree/react'
import { Link, useNavigate } from 'react-router-dom';
import scale_book from '../../assets/pdf/Scale_To_1K+_Fans_The_No_Bullshit_Guide.pdf'

function Book() {
    const [state, handleSubmit] = useForm("mkndzkjg");

    const Check = () => {
        return(
            <div className='p-[4px] lg:p-[6px] rounded bg-[#72B54C] flex items-center justify-center text-white mr-3 text-sm lg:text-xl font-bold'>
                <FiCheck />
            </div>
        )
    }

    return (
        <div className='w-full h-screen font-poppins bg-[#263341] bg-center bg-no-repeat bg-cover overflow-x-hidden py-[20px] lg:py-[50px] px-[20px]' style={{backgroundImage: `url(${bg})`}}>
            <div className='max-w-[1000px] mx-auto text-white'>
                <img className='h-[320px] mx-auto' src={book} alt='scale to 1k+ fans in 45 days andrew sabbagh' />
                <h1 className='text-lg lg:text-4xl font-clash font-semibold w-full text-center mt-5 lg:mt-10 flex items-center flex-wrap gap-x-4 justify-center'>EXPLODE YOUR <FaXTwitter /> BRAND TO 1K+ FANS</h1>
                <p className='text-xs w-full text-center mt-5'>This 30 page E-book teaches you how to attract 1K+ die hard fans & prepare for Automated Cash Flow</p>
                <p className='text-xs lg:text-xl w-full mt-5'>This Book gives you:</p>
                <div className='flex flex-col gap-2 lg:gap-5 mt-3 lg:mt-5'>
                    <div className='flex items-center'>
                        <Check /> 
                        <p className='text-xs lg:text-xl text-op-90'>The 7 Step quick <span className='font-semibold text-white'>DMs Formula</span> to attain <span className='font-semibold text-white'>+20 Die-hard fans daily</span> </p>
                    </div>
                    <div className='flex items-center'>
                        <Check /> 
                        <p className='text-xs lg:text-xl text-op-90 items-center'><span className='font-semibold text-white'>The "HBIC" Giveaway Blueprint I used to generate +25,000 view & 367 fans in 48 hours on</span> <span className='text-sm lg:text-xl pl-1'>𝕏</span></p>
                    </div>
                    <div className='flex items-center'>
                        <Check /> 
                        <p className='text-xs lg:text-xl text-op-90'>8 Case studies showcasing how to create unique content & maximise brand growth</p>
                    </div>
                    <div className='flex items-center'>
                        <Check /> 
                        <p className='text-xs lg:text-xl text-op-90'>BONUS Tweet Writing Center Worth $199 inside...</p>
                    </div>
                </div>
                <p className='text-xs lg:text-xl text-op-90 mt-5 lg:mt-10'>Get Instant Access to <span className='font-semibold text-white'>THE 1K+ DIE-HARD FANS E-BOOK + $199 BONUS</span> For FREE below:</p>
                {state.succeeded ? 
                    <div className='w-full text-center mt-10'>
                        <h1 className=' font-medium text-lg mb-5'>Done ✅. You now have access to this E-Book</h1>
                        <Link to={scale_book} target='_blank'><button className='bg-[#72B54C] h-[50px] px-5 rounded-full'>Download E-Book</button></Link>
                    </div>
                    :
                    <form onSubmit={handleSubmit} className='w-full flex flex-col md:flex-row items-center gap-5 mt-5 lg:mt-10'>
                        <div className='lg:flex-1 w-full'>
                            <input required type="email" name="email" id='email' className='h-[50px] text-black w-full rounded-full bg-[#eee] px-[20px] text-xs lg:text-base' placeholder='Enter email address ...' />
                            <ValidationError prefix="Email" field="email" errors={state.errors} />
                        </div>
                        <button className='bg-[#72B54C] h-[50px] px-5 w-full md:w-fit rounded-full font-medium text-xs lg:text-base'>{state.submitting ? 'Submitting...' : 'Instant Access'}</button>
                    </form>
                }
                <div className='mt-10 lg:mt-20 border-t border-t-layer-20-dark text-center pt-5'>
                    <p className='opacity-80'>By Andrew Sabbagh © 2024</p>
                </div>
            </div>
        </div>
    )
}

export default Book 