import React, { useState } from 'react'

function NewsLetter() {
    const [email, setEmail] = useState('')
    const [subscribed, setIsSubscribed] = useState(false);
    const [isSubscribing, setIsSubscribing] = useState(false);

    const handleClick = () => {
        if(email.length < 1) return
        setIsSubscribing(true);
        setTimeout(() => {
            setIsSubscribed(true)
        }, [1500])
    }

    return (
        <div className='w-full bg-black relative' data-aos="fade-up">
            <div className='w-full h-[300px] flex items-center text-white font-clash text-[100px] opacity-[0.07]'>
                The Preparation Geeza
            </div>
            <div className='w-full absolute top-0 h-full flex items-center'>
                {subscribed ? 
                    <div className='max-w-[1000px] mx-auto px-[20px] lg:px-[70px]'>
                        <h1 className='font-semibold text-2xl text-white text-center'>Subscribed <span className='text-4xl'>🚀</span></h1>
                        <p className='text-white mt-2 text-center'>Congrats, you are now ahead of 90% of brands.</p>
                    </div>
                :
                    <div className='max-w-[1000px] mx-auto px-[20px] lg:px-[70px]'>
                        <h1 className='font-semibold text-2xl text-white text-center'>Subscribe to my Newsletter</h1>
                        <p className='text-white mt-2 text-center'>Get the latest tips on how to grow your brand using social media.</p>
                        <div className='lg:max-w-[400px] mx-auto flex flex-col lg:flex-row items-center gap-5 lg:gap-2 mt-5 overflow-hidden'>
                            <input defaultValue={email} onChange={e => setEmail(e.target.value)} className='w-full lg:flex-1 h-[45px] pl-3 bg-button-dark text-white rounded-full focus:outline-none' type='email' placeholder='email address' />
                            <button className='w-full lg:w-fit px-5 h-[45px] bg-button-dark text-white rounded-full text-sm' onClick={handleClick}>{isSubscribing ? 'Subscribing...' : 'Subscribe'}</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default NewsLetter