export const FAQs = [
    {
        question: 'How do you ensure brand tailored content?',
        answer: 'Before starting any project, I take the time to understand your brand, target audience, voice, and tone. This helps me create content that aligns with your brand identity and resonates with your audience'
    },
    {
        question: 'What industries do you specialize in?',
        answer: 'I have experience writing for various industries including technology, healthcare, finance, lifestyle, travel, and more.'
    },
    {
        question: 'What is your approach to SEO in content writing?',
        answer: "I understand the importance of SEO in today's digital landscape. I incorporate relevant keywords and phrases naturally into the content while ensuring it remains informative and engaging for readers."
    },
    {
        question: 'What are your rates for content writing services?',
        answer: 'My rates vary depending on the scope and complexity of the project. Factors such as word count, research requirements, and deadlines can also influence pricing.'
    }
]