import React, { useEffect, useState } from 'react';

const services = [
    'Content Strategy',
    'Content Workshop',
    'Content Production',
    'Other'
]

function Contact() {
    const [selectedServices, setSelectedService] = useState([]);

    const handleClick = e => {
        const indexOfService = selectedServices.indexOf(e)
        if(indexOfService > -1){
            let newArr = [...selectedServices]
            newArr.splice(indexOfService, 1)
            setSelectedService(newArr)
        }else{
            setSelectedService(prev => [...prev, e])
        }
    }

    const PreferredService = ({name, isSelected}) => {
        return(
            <div onClick={() => handleClick(name)} className={`px-4 py-2 text-sm lg:text-base rounded-full border border-[#ddd] dark:bg-dark-bg dark:border-layer-10-dark cursor-pointer ${isSelected ? 'bg-[#ddd] dark:bg-[#222222]' : 'bg-[#f5f5f5]'} `}>
                {name}
            </div>
        )
    }

    return (
        <div className='w-full px-[20px] py-20 text-black dark:text-white' id='contact'>
            <div className='max-w-[1200px] mx-auto flex flex-col lg:flex-row gap-10'>
                <div className='flex-1 lg:mt-10' data-aos="fade-right">
                    <h1 className='text-[60px] font-clash'>Work With Me</h1>
                    <p className='opacity-80 dark:opacity-90 mt-4'>Send me a DM let's get your brand the right contents it needs</p>
                    <p className='opacity-80 dark:opacity-90 mt-4'>If you would rather send an email, you can reach me at <a href='' target='_blank' className='underline'>contact@sairs.me</a></p>
                </div>
                <div className='w-full flex-1 flex flex-col gap-5' data-aos="fade-left">
                    <div className='flex flex-col gap-2'>
                        <p className='font-medium pl-3'>Fullname</p>
                        <input className='w-full h-[50px] rounded-full bg-[#f5f5f5] dark:bg-dark-bg border border-[#ddd] dark:border-layer-10-dark focus:outline-none pl-5' placeholder='Enter Fullname' />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p className='font-medium pl-3'>Email address</p>
                        <input className='w-full h-[50px] rounded-full bg-[#f5f5f5] dark:bg-dark-bg border border-[#ddd] dark:border-layer-10-dark focus:outline-none pl-5' type='email' placeholder='Enter email address' />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p className='font-medium pl-3'>Perferred Service</p>
                        <div className='flex items-center gap-3 flex-wrap'>
                            {services.map((i, idx) => (
                                <PreferredService
                                    name={i}
                                    isSelected={selectedServices.indexOf(i) > -1}
                                    key={idx}
                                />
                            ))}
                        </div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p className='font-medium pl-3'>More Details</p>
                        <textarea className='w-full h-[150px] rounded-[15px] bg-[#f5f5f5] border border-[#ddd] dark:bg-dark-bg dark:border-layer-10-dark focus:outline-none pl-5 pt-3' type='email' placeholder='More info about this project' />
                    </div>
                    <button className='bg-black dark:bg-button-dark text-white py-3 rounded-full font-medium mt-5'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default Contact