import React, { useState } from 'react'
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FaXTwitter, FaInstagram, FaFacebook, FaLinkedinIn } from "react-icons/fa6";
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import classes from './socials.module.css';
import profile_pic from '../../assets/andrew_pic.JPG'

let width;
if (typeof window !== "undefined") {
    // Client-side-only code
    width = window.innerWidth;
}

function Socials() {
    //UI state
    const [offset, setOffset] = useState(0);

    const [showRight, setShowRight] = useState(true);
    const [showLeft, setShowLeft] = useState(false);

    function handleRightClick() {
        setOffset((prevOffset) => {
            if (prevOffset > -1000) {
                setShowLeft(true);
                setShowRight(true)
                if(width > 600){
                    return prevOffset - 500;
                }else{
                    return prevOffset - 200;
                } 
            }
            setShowRight(false)
            return prevOffset;
        });
    }

    function handleLeftClick() {
        setOffset((prevOffset) => {
            if (prevOffset < 0) {
                setShowLeft(true);
                setShowRight(true)
            if(width > 600){
                return prevOffset + 500;
            }else{
                return prevOffset + 200
            }
                
                
            }
            setShowLeft(false);
            return prevOffset;
        });
    };

    const Card = ({children, date}) => {
        return(
            <div className='w-full h-fit p-3 bg-[#fff] dark:bg-dark-bg rounded-lg shadow-lg text-black dark:text-white border border-lines dark:border-layer-10-dark '>
                <div className='flex items-center gap-3'>
                    <img src={profile_pic} alt='andrew sabbagh twitter image' className='h-[40px] w-[40px] rounded-full bg-[#ddd] object-fit' />
                    <div>
                        <div className='flex items-center gap-1'>
                            <h3 className='font-semibold'>Andrew Sabbagh</h3>
                            <RiVerifiedBadgeFill className='text-black dark:text-white opacity-40' />
                        </div>
                        <p className='text-sm opacity-80'>@Andrewsairs</p>
                    </div>
                </div>
                {children}
                <p className='mt-3 text-sm opacity-70'>{date}</p>
            </div>
        )
    }
    return (
        <div className='w-full py-20 overflow-hidden text-black dark:text-white' id='socials'>
            <div className='max-w-[1000px] px-[20px] mx-auto border-black'  data-aos="fade-up">
                <h1 className='font-clash text-4xl'>My Thoughts</h1>
                <div className='flex items-center mt-10 gap-3'>
                    <img src={profile_pic} alt='andrew sabbagh twitter image' className='h-[50px] w-[50px] rounded-full bg-[#ddd] object-fit' />
                    <div>
                        <div className='flex items-center gap-2'>
                            <h3 className='font-semibold text-lg'>Andrew Sabbagh</h3>
                            <RiVerifiedBadgeFill className='text-primary text-lg' />
                        </div>
                        <p className='text-sm opacity-80'>@Andrewsairs</p>
                    </div>
                    <a href='https://twitter.com/Andrewsairs' target='_blank'className='hidden lg:block' ><button className='border border-black dark:border-layer-10-dark text-black dark:text-white py-2 px-4 rounded-full ml-5 flex items-center font-medium gap-2'>Follow on <FaXTwitter className='text-lg' /></button></a>
                    <div className='flex-1 hidden lg:flex items-center justify-end gap-3'>
                        <div className={`h-[50px] w-[50px] rounded-full bg-[#fff] border ${showLeft ? 'text-white bg-black dark:bg-button-dark dark:border-button-dark' : 'text-black border-black dark:text-white dark:bg-dark-bg dark:border-dark-bg'} flex items-center justify-center text-2xl cursor-pointer`} onClick={handleLeftClick}><FiArrowLeft /></div>
                        <div className={`h-[50px] w-[50px] rounded-full bg-[#fff] border ${showRight ? 'text-white bg-black dark:bg-button-dark dark:border-button-dark' : 'text-black border-black dark:text-white dark:bg-dark-bg dark:border-dark-bg'} flex items-center justify-center text-2xl cursor-pointer`} onClick={handleRightClick}><FiArrowRight /></div>
                    </div>
                </div>
            </div>
            <div className={`max-w-[1000px] px-[20px] overflow-x-scroll lg:overflow-x-visible mx-auto py-5 grid gap-5 lg:gap-10 ${classes.scrollSectionContainer}`} style={{transform: `translateX(${offset}px)`}}  data-aos="fade-up">
                <Card date={'3:45 AM · Feb 5, 2024'}>
                    <p className='mt-3'>Grind to grow your business <br /> Automate it for passive income <br /> It is that simple.</p>
                </Card>
                <Card date={'2:04 PM · Apr 19, 2024'}>
                    <p className='mt-3'>Best way to 10x your growth? Do umcomfortable shit<br /> <p className='mt-3'> Change your offer <br /> Change your funnel. <br /> Change your content strategy</p></p>
                </Card>
                <Card date={'10:50 AM · Apr 15, 2024'}>
                    <p className='mt-3'>Pitch to your parents. Pitch them your offer. <br /> If they say yes?<br /> Your offer is good.</p>
                </Card>
                <Card date={'09:56 AM · Apr 13, 2024'}>
                    <p className='mt-3'>Write fu** you posts. <br />Save viral tweet structures<br /> Read books.</p>
                </Card>
            </div>
            <div className='w-full px-[20px] text-center mt-14'  data-aos="fade-up">
                <h3 className='font-medium text-xl opacity-80'>Other Socials</h3>
                <div className='flex items-center justify-center gap-5 text-4xl mt-4'>
                    <a href='' target='_blank'><FaLinkedinIn className='opacity-30 hover:opacity-100 hover:text-primary' /></a>
                    <a href='' target='_blank'><FaInstagram className='opacity-30 hover:opacity-100 hover:text-primary'/></a>
                    <a href='' target='_blank'><FaFacebook className='opacity-30 hover:opacity-100 hover:text-primary'/></a>
                </div>
            </div>
        </div>
    )
}

export default Socials