import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { FAQs } from '../../constants/FAQ.constants';

function FAQ() {
    const [selectedAnswer, setSelectedAnswer] = useState(0);

    const Item = ({question, answer, showAnswer, onClick}) => {
        return(
            <div className='w-full py-4 border-b border-lines dark:border-layer-10-dark text-black dark:text-white'>
                <div className='flex items-center gap-2'>
                    <h3 className='font-medium text-lg flex-1'>{question}</h3>
                    <div className='h-[40px] w-[40px] rounded-full hover:bg-[#eee] dark:hover:bg-button-dark flex items-center justify-center cursor-pointer text-2xl' onClick={onClick}>{showAnswer ? <FiMinus /> : <FiPlus />}</div>
                </div>
                <AnimatePresence>
                    {showAnswer && (
                        <motion.div 
                            className='mt-4 opacity-80 dark:opacity-90'
                            initial={{ height: '0px' }}
                            animate={{ height: '' }}
                            exit={{ height: '0px' }}
                        >
                            {answer}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        )
    }

    const handleClick = (index) => {
        if(index === selectedAnswer){
            setSelectedAnswer(0)
            return
        }
        setSelectedAnswer(index)
    }

    return (
        <div className='w-full py-20 px-[20px]'  id='faq'>
            <div className='max-w-[1000px] mx-auto flex flex-col lg:flex-row gap-10 lg:gap-16'>
                <div className='flex-1 lg:mt-20 text-black dark:text-white' data-aos="fade-right">
                    <h1 className='font-clash text-4xl'>Frequently Asked Questions</h1>
                    <p className='mt-2 opacity-80 dark:opacity-90'>Having concerns before reaching out? here are a couple of the most asked questions I get.</p>
                </div>
                <div className='flex-1 flex flex-col' data-aos="fade-left">
                    {FAQs.map((i, idx) => (
                        <Item
                            question={i.question}
                            answer={i.answer}
                            showAnswer={idx + 1 === selectedAnswer}
                            onClick={() => handleClick(idx + 1)}
                            key={idx}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FAQ