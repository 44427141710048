import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import NewsLetter from '../../components/newsletter/NewsLetter'
import FAQ from '../../components/faq/FAQ'
import Socials from '../../components/socials/Socials';
import Contact from '../../components/contact/Contact';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import profile_pic from '../../assets/andrew_pic.JPG'

function Home() {
    const [currentTheme, setCurrentTheme] = useState('light');

    useEffect(() => {
        const localTheme = localStorage.getItem('@theme');
        if(localTheme === 'dark'){
            setCurrentTheme(localTheme)
        }
        AOS.init()
    }, []);

    const handleScroll = (e) => {
        const element = document.getElementById(e);
        if (element) {
            //Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <div className={`${currentTheme === 'dark' && 'dark'}`}>
            <div className={`font-poppins bg-[#fff] dark:bg-[#0C0C0C] overflow-x-hidden`}>
                <Header currentTheme={currentTheme} setTheme={e => setCurrentTheme(e)} handleScroll={handleScroll} />
                <div className='w-full py-16 px-[20px] lg:px-[100px] mt-[70px]' id='about'>
                    <img className='w-[150px] h-[150px] rounded-full mx-auto bg-[#f5f5f5] object-cover' data-aos="fade-up" src={profile_pic} alt='Andrew Sabbagh' />
                    <h1 className='text-2xl font-medium mx-auto max-w-[500px] text-center mt-5 text-black dark:text-white' data-aos="fade-up">Hi - I'm Andrew Sabbagh. I help brands perpare for 10X Growth and 6 Figure earnings.</h1>
                    <div className='w-full flex flex-col lg:flex-row gap-10 lg:gap-16 mt-16 max-w-[1000px] mx-auto text-black dark:text-white'>
                        <div className='flex-1' data-aos="fade-right">
                            <p className='text-lg'> I understand that every brand has a unique story to tell, and I'm here to help bring that story to life in a way that resonates with your target audience.</p>
                            <p className='text-lg mt-3'>But I don't just stop at creating content—I'm committed to driving results. Through strategic storytelling, I help brands increase engagement, boost brand awareness, and ultimately, drive conversions.</p>
                            <button className='bg-black dark:bg-button-dark rounded-full px-5 py-3 text-white mt-5' onClick={() => handleScroll('contact')}>Contact Me</button>
                        </div>
                        <div className='flex-1' data-aos="fade-left">
                            <div className='flex items-center gap-2'>
                                <div className='h-[8px] w-[8px] rounded-full bg-primary' />
                                <h3 className='font-medium text-lg uppercase'>Availiable For</h3>
                            </div>
                            <div className='w-full flex flex-col'>
                                <div className='py-3 px-2 border-b border-lines dark:border-layer-10-dark text-lg'>Content Strategy</div>
                                <div className='py-3 px-2 border-b border-lines dark:border-layer-10-dark text-lg'>Speaking Engagements</div>
                                <div className='py-3 px-2 border-b border-lines dark:border-layer-10-dark text-lg'>Content Writings</div>
                                <div className='py-3 px-2 border-b border-lines dark:border-layer-10-dark text-lg'>Consultations</div>
                            </div>
                        </div>
                    </div>
                </div>
                <NewsLetter />
                <FAQ />
                <div className='w-full py-20 px-[20px] bg-[#f5f5f5] dark:bg-dark-bg text-center' data-aos="fade-up">
                    <h1 className='font-semibold text-3xl lg:text-5xl font-clash font-medium mx-auto max-w-[700px] text-black dark:text-white' data-aos="fade-up">Preparing Brands for 10x Growth</h1>
                    <button className='px-5 py-3 bg-[#000] dark:bg-white rounded-full text-white dark:text-black dark:font-semibold mt-5' data-aos="fade-up" onClick={() => handleScroll('contact')}>Work with me</button>
                </div>
                <Socials />
                <div className='w-full overflow-hidden' data-aos="fade-left">
                    <div className='font-bold text-6xl lg:text-[200px] text-black dark:text-white opacity-10 dark:opacity-[0.06] flex items-center gap-3 lg:gap-10 font-clash'><p>Andrew</p><p>Sabbagh</p></div>
                </div>
                <Contact />
                <div className='bg-[#eee] dark:bg-dark-bg text-black dark:text-white py-2 font-medium text-center'>Built with ❤️ + ☕ by <a href='https://twitter.com/ifeanyicodes' target='_blank' rel="noreferrer" className='underline'>Fvture</a></div>
            </div>
        </div>
    )
}

export default Home