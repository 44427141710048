import Book from './pages/book/Book';
import Home from './pages/home/Home';

import {
  createBrowserRouter,
} from "react-router-dom";

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/scale-your-brand-to-1k-fans',
    element: <Book />
  }
])